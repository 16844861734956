<template>
  <section class="Category">
    <Loader :loading="showLoader" />
    <div class="page-header mb-0">
      <h3 class="page-title pt-2">Edit Tag</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb p-0">
          <li class="breadcrumb-item">
            <router-link to="/catalog/tag" class="text-primary"> Tag
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Edit Tag</li>
        </ol>
      </nav>
    </div>

    <form class="pt-2" @submit.prevent="editTag">
      <div class="row pt-2" v-if="tag">
        <div class="col-lg-12">
          <div class="card mb-3">
            <div class="card-body">
              <div class="form-group  mb-1">
                <label class=" fs-12 p-0 m-0 col-form-label" for="name">Name
                </label>
                <div class="form-group">
                  <input type="text" v-model="tag.name" id="name" name="name" class="form-control" :class="{
                    'is-invalid': submitted && $v.tag.name.$error,
                  }" />
                  <div v-if="submitted && !$v.tag.name.required" class="invalid-feedback">
                    Name is required
                  </div>

                </div>
              </div>
              <div class="form-group  mb-1">
                <label class=" m-0 p-0 fs-12 col-form-label" for="name">Sort </label>

                <div class="form-group">
                  <input type="number" v-model="tag.sort" class="form-control" />
                </div>

              </div>

              <div class="form-group  ">
                <label for=""> Status </label>
                <div class="form-group mb-0">
                  <toggle-button :value="true" v-model="tag.active" :labels="{ checked: 'true', unchecked: 'false' }" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group float-right mt-3">
            <button class="btn btn btn-add btn-primary">Update</button>
          </div>
        </div>
      </div>
    </form>


  </section>
</template>

<script>
import { catalogApi } from "../../../api";
import { required } from "vuelidate/lib/validators";
import Loader from "../../../components/Loader";
export default {
  name: "edit-tag",
  edit: {
    active: Boolean,
    sort: Number,
    name: String,
  },
  components: {
    Loader,
  },
  data() {
    return {
      tag: this.edit,
      submitted: false,
      showLoader: false,
    };
  },
  validations: {
    tag: {
      name: { required },
    },
  },
  mounted() {
    this.getTagById();
  },
  methods: {
    async getTagById() {
      this.showLoader = true;
      const { data } = await catalogApi.getTagById(this.$route.params.id);
      let tags = data.data.data;
      tags.forEach((value) => {
        this.tag = value;
        if (this.tag.active === 1) {
          this.tag.active = true;
        } else {
          this.tag.active = false;
        }
      });
      this.showLoader = false;
    },
    async editTag() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      if (this.tag.active === true) {
        this.tag.active = 1;
      } else {
        this.tag.active = 0;
      }

      this.showLoader = true;
      const data = await catalogApi.updateTag(this.$route.params.id, {
        ...this.tag,
      });
      this.showLoader = false;
      this.$router.push("/catalog/tag");
      if (data.status == 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.messages,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.messages,
        });
      }
    },
  },
};
</script>